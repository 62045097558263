import React from 'react';
import { FaTelegram, FaTwitter, FaDiscord, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="footer">
      {/* <h1>WARNING</h1>
      <p>Do not sacrifice to any address other than those cross posted on official sources below:</p> */}
      <p style={{fontSize: "24px"}} >
      Website: <a href="https://pulseverse.io" target="_blank" rel="noopener noreferrer">https://pulseverse.io</a>
        </p>
      <div className="footer-container">

        <div className="social-icons">
          <a href="https://t.me/PulseVerse" target="_blank" rel="noopener noreferrer">
            <FaTelegram />
          </a>
          <a href="https://twitter.com/PulseVerseio" target="_blank" rel="noopener noreferrer">
            <FaTwitter />
          </a>
          <a href="https://discord.com/invite/veUkyeVqPB" target="_blank" rel="noopener noreferrer">
            <FaDiscord />
          </a>
          <a href="https://www.youtube.com/channel/UCKOSvI8P-NQFLXfUcPP61jw" target="_blank" rel="noopener noreferrer">
            <FaYoutube />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;