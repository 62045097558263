import logo from './logo-wtrans.png';
import './App.css';
import { useState, useRef } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import points from "./points.json";
import tokens from "./tokens.json";
import { FaCopy } from 'react-icons/fa';
import Countdown from './components/Countdown';
import DaysBeen from './components/DaysBeen';
import Footer from './components/Footer';

function App() {
  const targetDate = '2023-07-07T00:00:00Z';
  const sacAddress = "0x0fd7faC1F86D13EBDB1fD2997B541303Cc37fa29";
  const [copyText, setCopyText] = useState(null)
  const copyAddress = () => {
    setCopyText("Copied!");
    setTimeout(() => {
      setCopyText(null);
    }, 5000)
  };

  const sacrificeElement = useRef();

  const executeScroll = () => {
    sacrificeElement.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    })
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="header">
          <img src={logo} className="App-logo" alt="logo" />
        </div>
      </header>
      {/* <Countdown targetDate={targetDate} /> */}
      {/* <DaysBeen targetDate={targetDate}/> */}
      {/* <button id="sac" onClick={executeScroll}>Sacrifice Now!</button> */}
      {/* <div className="disclaimer mt-4">
        <span><span style={{ "textDecoration": "underline", "fontWeight": "bolder" }}>*IMPORTANT DISCLAIMER*</span><br />
          IF YOU CHOOSE TO SACRIFICE… YOU MUST HAVE NO EXPECTATION OF PROFIT FROM THE WORK OF OTHERS.</span>
      </div>
      <div className="pol">
        <h1>Political Statement</h1>
        <p>"You are sacrificing to show you believe in the right to economic freedom which is the ability of people within a society to control how they earn and spend their money. It is the fundamental right of every human to control their own labor and property and apply it in any way they please. Within the DeFI and NFT space this right may be more fully realized and in the process bringing more adoption to the PulseChain network."</p>
      </div> */}

      <div className='vertical-center'>
        <div className="info">
          <span>Sacrifice Closing Early</span><br />
          <p>After careful deliberation we have decided to close the sacrifice early. We were not able to hit the goals that we needed to fund the project. All sacrifices have been refunded.<br />Though our sacrifice did not pan out as hoped, we deeply value your trust & support.<br/>Please head to our social media links for more information.</p>
          <Footer/>
        </div>
      </div>

      {/* <div className="info">
        <span>Sacrifice Rate Change</span><br />
        <div className="container">
          <div className="column text-column">
            <p>Sacrifices will be credited sacrifice points based on sacrificing to the specified wallet. These points do not have value. In the future, someone may decide to mint PLSV tokens based on the number of points an address is allocated.</p>
            <p>For the first 30 days of the sacrifice phase, sacrifices will be allocated a decreasing amount of sacrifice points according to the table per each $1 USD value sacrificed. On day 31 and onwards, 1 sacrifice point will be allocated per each $1 USD value sacrificed.</p>
          </div>
          <div className="column table-column">
            <table>
              <thead>
                <tr>
                  <th style={{fontSize: "18px"}}>Day</th>
                  <th style={{fontSize: "18px"}}>Date</th>
                  <th style={{fontSize: "18px"}}>Ratio</th>
                </tr>
              </thead>
              <tbody>
                {points.map((value, key) => {
                  return (<tr key={key}>
                    <td>{value["day"]}</td>
                    <td>{value["date"]}</td>
                    {value["span"] && <td rowSpan={value["span"]}>{value["ratio"]}</td>}
                  </tr>);
                })}
              </tbody>
            </table>
          </div>
        </div>


      </div>
      <div className="info">
        <span>Bonus Multiplier</span><br />
        <p>As a tribute to previous sacrifice events and projects contributing to the development and promotion of the PulseChain ecosystem, a bonus multiplier will be applied to any sacrifice points allocated in this current sacrifice for those wallet addresses that were involved in the sacrifices listed below. This is done in recognition of their dedication to the right to economic freedom.</p>
        <table>
          <thead>
            <tr>
              <th style={{fontSize: "18px"}}>Project</th>
              <th style={{fontSize: "18px"}}>Bonus Multiplier</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PulseChain</td>
              <td>10%</td>
            </tr>
            <tr>
              <td>PulseX</td>
              <td>10%</td>
            </tr>
            <tr>
              <td>Liquid Loans</td>
              <td>5%</td>
            </tr>
            <tr>
              <td>PowerCity</td>
              <td>5%</td>
            </tr>
          </tbody>
        </table>
        <p style={{marginBottom: "0px"}}>Some examples of calculating bonuses:</p>
        <div className="examples">
          <div className="example">
            <h2>Example 1:</h2>
            <p>Suppose you sacrificed $10,000 on Day 10 (5x bonus), and you also sacrificed to all four previous project sacrifices, the bonus could be calculated as:
              (10% + 10% + 5% + 5%) = 30%</p>
            <p>
              The sacrifice points calculation could be as follows:
              (10,000 * 5) + (10,000 * 30%) =
              53,000 sacrifice points.</p>
          </div>
          <div className="example">
            <h2>Example 2:</h2>
            <p>Suppose you sacrificed $2,500 on day 23 (3x bonus). And previously you sacrificed to PulseChain (10%) & Liquid Loans (5%), the bonus could be calculated as:
              (10% + 5%) = 15%</p>
            <p>
              The sacrifice points calculation could be as follows:
              (2,500 * 3) + (2,500 * 15%) =
              7,875 sacrifice points.</p>
          </div>
          <div className="example">
            <h2>Example 3:</h2>
            <p>Suppose you sacrificed $1,500 on Day 30 (2x bonus), and you didn't participate in the sacrifices above. Your bonus could be calculated as:
              (1,500 * 2) =
              3,000 sacrifice points.</p>
          </div>
          <div className="example">
            <h2>Example 4:</h2>
            <p>Suppose you sacrificed $5000 on Day 100, and you didn't participate in the sacrifices above. There is no bonus at this time:
              (5,000 * 1) =
              5,000 sacrifice points.</p>
          </div>
        </div>
      </div>
      <div className="info">
        <span>Sacrifice Duration</span><br />
        <p>The sacrifice will start on July 7th 2023 and will go to July 7th 2024. <br />NOTE - Any sacrifice sent to the sacrifice address after the end of the sacrifice phase will not count and will be forfeited meaning they earn no sacrifice points.</p>
      </div>
      <div className="info" style={{ overflow: "scroll", height: "1000px" }}>
        <span>Accepted Tokens</span><br />
        <div className="accepted-table">
          <table>
            <thead>
              <tr>
                <th colSpan="2">Ethereum</th>
                <th colSpan="2">BNB Smart Chain</th>
                <th colSpan="2">PulseChain</th>
                <th colSpan="2">Avalanche</th>
                <th colSpan="2">Fantom</th>
                <th colSpan="2">Polygon</th>
              </tr>
            </thead>
            <tbody>
              {tokens["ethereum"].map((value, key) => {
                return (
                  <tr key={key}>
                    <td>{tokens["ethereum"][key] && tokens["ethereum"][key]["token"]}</td>
                    <td style={{ color: "#0D2968" }}>{tokens["ethereum"][key] && tokens["ethereum"][key]["symbol"]}</td>

                    <td>{tokens["bnb"][key] && tokens["bnb"][key]["token"]}</td>
                    <td style={{ color: "#0D2968" }}>{tokens["bnb"][key] && tokens["bnb"][key]["symbol"]}</td>

                    <td>{tokens["pulsechain"][key] && tokens["pulsechain"][key]["token"]}</td>
                    <td style={{ color: "#0D2968" }}>{tokens["pulsechain"][key] && tokens["pulsechain"][key]["symbol"]}</td>

                    <td>{tokens["avalanche"][key] && tokens["avalanche"][key]["token"]}</td>
                    <td style={{ color: "#0D2968" }}>{tokens["avalanche"][key] && tokens["avalanche"][key]["symbol"]}</td>

                    <td>{tokens["fantom"][key] && tokens["fantom"][key]["token"]}</td>
                    <td style={{ color: "#0D2968" }}>{tokens["fantom"][key] && tokens["fantom"][key]["symbol"]}</td>

                    <td>{tokens["polygon"][key] && tokens["polygon"][key]["token"]}</td>
                    <td style={{ color: "#0D2968" }}>{tokens["polygon"][key] && tokens["polygon"][key]["symbol"]}</td>
                  </tr>
                )
              })}
            </tbody>


          </table>
        </div>

      </div>
      <div className="info">
        <span>Multiple Sacrifices</span><br />
        <p>During the sacrifice period, you may sacrifice as many times as you wish from the same wallet. Your total points will be an aggregate of all these amounts. The sacrifice transaction from your wallet will contain all the information needed to calculate the total amount sacrificed, the points received for the sacrifice, and any bonus multiplier points you may receive.</p>
        <p>Everyone's sacrifice can be publicly viewed during the period by checking the public balances of the sacrifice address that will be provided when the sacrifice phase starts.</p>
      </div>
      <div className="info" id="sacrifice-now" ref={sacrificeElement}>

        <div className='content'>
        <h1 style={{marginBottom: "0px"}}>Sacrifice Address</h1><br />
        <div className="copy-input-container">
          <input type="text" value={sacAddress} className="copy-input" readOnly />
          <CopyToClipboard text={sacAddress}
            onCopy={copyAddress} className="copy-button">
            <button>
              <FaCopy style={{ color: "black" }} />
            </button>
          </CopyToClipboard>
        </div>
        </div>
        {copyText && <p style={{ marginTop: "5px" }}>{copyText}</p>}
        <Footer/>

      </div>
      <div className="disclaimer mt-4">
        <span><span style={{ "textDecoration": "underline", "fontWeight": "bolder" }}>*DISCLAIMER REMINDER*</span><br />
          IF YOU CHOOSE TO SACRIFICE… YOU MUST HAVE NO EXPECTATION OF PROFIT FROM THE WORK OF OTHERS.</span>
      </div> */}

      {/* <div className="footer-copyright text-center py-3" style={{float: "bottom"}}>
        <span>Copyright © pulseverse.io</span>
      </div> */}
    </div>
  );
}

export default App;
